@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Montserrat", sans-serif;
  }
}

.bottom-corner {
  shape-outside: inset(calc(80% - 100px) 0 0);
}